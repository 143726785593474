<template>
  <div class="time-picker">
    <v-menu
      ref="menu"
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :outlined="outlined"
          :dense="dense"
          v-model="timeFormatted"
          :label="label"
          :append-icon="appendIcon ? 'access_time' : null"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="open"
        v-model="time"
        full-width
        format="24hr"
        @click:minute="$refs.menu.save(time)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      required: true
    },
    label: {
      default: "Zeit",
      type: String
    },
    appendIcon: {
      type: Boolean,
      default: true
    },
    outlined: {
      default: true
    },
    dense: {
      default: true
    }
  },

  data: () => ({
    open: false
  }),

  computed: {
    time: {
      get() {
        return this.value ? this.value.format("HH:mm:ss") : null;
      },
      set(value) {
        this.$emit("input", moment(value, "HH:mm:ss"));
      }
    },

    timeFormatted() {
      return this.value ? this.value.format("HH:mm") : "-";
    }
  }
};
</script>

<style></style>
