<template>
  <div class="remote-data-table">
    <v-row v-if="searchEnabled">
      <v-col>
        <v-text-field
          outlined
          dense
          v-model="search"
          prepend-inner-icon="search"
          label="Suche..."
          @keyup.enter="getDataFromApi()"
          :maxlength="100"
        ></v-text-field>
      </v-col>
      <v-col v-for="(filter, index) in filters" :key="index" cols="auto">
        <v-checkbox
          v-if="filter.type === 'checkbox'"
          v-model="selectedFilterValues[index].value"
          :label="filter.label"
          class="mt-1"
        ></v-checkbox>
        <v-select
          outlined
          dense
          v-if="filter.type === 'select'"
          v-model="selectedFilterValues[index].value"
          :items="filter.values"
          item-text="text"
          item-value="value"
          :label="filter.label"
          single-line
        ></v-select>
        <date-picker
          v-if="filter.type === 'datepicker'"
          v-model="selectedFilterValues[index].value"
          :label="filter.label"
        />
        <time-picker
          v-if="filter.type === 'timepicker'"
          v-model="selectedFilterValues[index].value"
          :label="filter.label"
        />
      </v-col>
      <v-col cols="auto">
        <tooltip-button
          icon="clear"
          text="Zurücksetzen"
          color="default"
          :dark="false"
          @click="resetFilters()"
          classNames="elevation-0 mt-1 mb-1"
          position="top"
          size="x-small"
        />
      </v-col>
      <v-col cols="auto">
        <v-chip class="mt-1" label>{{ total }}</v-chip>
      </v-col>
    </v-row>
    <v-data-table
      must-sort
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :show-expand="showExpand"
      :single-expand="showExpand"
      :items-per-page="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions,
        showFirstLastPage: true
      }"
      :page="page"
      dense
      class="elevation-0"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from "@/components/forms/DatePicker";
import TimePicker from "@/components/forms/TimePicker";
import TooltipButton from "@/components/forms/TooltipButton";

export default {
  components: {
    TimePicker,
    DatePicker,
    TooltipButton
  },

  props: {
    headers: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    sortBy: {
      default: null,
      required: false
    },
    searchEnabled: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    watchers: {
      type: Array,
      default: () => []
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    with: {
      type: Array,
      default: () => []
    },
    appendUrlParams: {
      type: String,
      default: ""
    },
    allowAll: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [],
    total: 0,
    loading: true,
    options: {},
    page: 1,
    itemsPerPage: 15,
    sort: "",
    search: "",
    selectedFilterValues: [],
    fetchedData: false
  }),

  computed: {
    itemsPerPageOptions() {
      return this.allowAll ? [-1, 5, 10, 15, 30] : [5, 10, 15, 30];
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },

    selectedFilterValues: {
      handler(values) {
        if (this.fetchedData) {
          this.getDataFromApi();
        }

        const vm = this;
        this.watchers.forEach(watcher => {
          watcher(vm, values);
        });

        this.page = 1;
      },
      deep: true
    }
  },

  created() {
    if (this.sortBy) {
      this.sort = this.sortBy;
    }

    if (this.filters && this.filters.length > 0) {
      this.selectedFilterValues = this.filters.map(filter => ({
        key: filter.field,
        value: filter.default,
        type: filter.type
      }));
    }
  },

  methods: {
    /**
     * Fet data from API.
     */
    getDataFromApi() {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.itemsPerPage = itemsPerPage >= 0 ? itemsPerPage : this.total;
      this.page = page;

      // Prepare sort parameter.
      if (sortBy.length > 0) {
        this.sort = (sortDesc[0] ? "" : "-") + sortBy[0];
      }

      // Prepare filter parameters.
      let filterParams = null;
      if (this.selectedFilterValues.length > 0) {
        filterParams = this.selectedFilterValues.map(filter => {
          let value = filter.value;
          if (filter.type === "datepicker" && filter.value) {
            value = filter.value.format("YYYY-MM-DD");
          } else if (filter.type === "timepicker" && filter.value) {
            value = filter.value.format("HH:mm") + ":00";
          }
          return filter.value ? `&${filter.key}=${value}` : "";
        });
      }

      // Fetch data from server.
      this.$api.http
        .get(
          `${this.url}?_page=${this.page}&_per_page=${this.itemsPerPage}&_sort=${this.sort}` +
            (this.with.length > 0 ? `&_with=${this.with.join(",")}` : "") +
            (this.searchEnabled ? `&_search=${this.search}` : "") +
            (filterParams ? filterParams.join("") : "") +
            (this.appendUrlParams ? "&" + this.appendUrlParams : "")
        )
        .then(response => {
          this.items = response.data.data;
          this.total = response.data.meta.total;

          this.loading = false;
          this.fetchedData = true;
        });
    },

    /**
     * Reset all filters and fetch data.
     */
    resetFilters() {
      this.search = "";
      this.selectedFilterValues.forEach((filterValue, index) => {
        this.selectedFilterValues[index].value = this.filters[index].default;
      });
      this.getDataFromApi();
      this.page = 1;
    }
  }
};
</script>
