<template>
  <v-dialog v-model="open" scrollable width="700">
    <v-form v-model="valid" v-if="cateringCategory" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="mode === 'create'">Kategorie erfassen</span>
          <span v-if="mode === 'edit'">Kategorie bearbeiten</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                v-model="cateringCategory.name"
                label="Name"
                type="text"
                :rules="textRules"
                :maxlength="255"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    mode: "create",
    valid: false,

    cateringCategoryId: null,
    cateringCategory: null,

    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        if (this.mode === "create") {
          this.resetForm();
        } else if (this.mode === "edit") {
          this.fetchCateringCategory();
        }
      }
    }
  },

  computed: {
    ...mapState("app", ["configs"]),

    categories() {
      return this.configs.catering_categories.map(cat => ({
        name: cat.name,
        value: cat.id
      }));
    }
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.cateringCategory = {
        id: null,
        name: null
      };
    },

    /**
     * Fetch catering category from server
     */
    fetchCateringCategory() {
      this.$api.http
        .get(`backoffice/catering-categories/${this.cateringCategoryId}`)
        .then(response => {
          this.cateringCategory = response.data;
        });
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save catering category.
     */
    save() {
      if (this.mode === "create") {
        this.$api.http
          .post(`backoffice/catering-categories`, this.cateringCategory)
          .then(response => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      } else if (this.mode === "edit") {
        this.$api.http
          .put(
            `backoffice/catering-categories/${this.cateringCategoryId}`,
            this.cateringCategory
          )
          .then(response => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      }
    }
  }
};
</script>
